
img{
    height:auto;
    width: auto;
}
.h-12{
    height:12px
}
.h-14{
    height:14px
}
.h-17{
    height:17px
}
.h-20{
    height:20px
}
.h-40{
    height:40px;
}
.h-45{
    height:45px;
}
.border-rad-50{
    border-radius: 50%;
}
.cursor-pointer{
    cursor: pointer;
}
 .user-select{
    user-select: none
 }
 .z-index2{
     z-index: 2;
 }
 .t-capitalize::first-letter{
     text-transform: capitalize;
 }
 .otherInputBorder{
    input{
        border-color: #d9d9d9!important;
    }
 }
 .form-collapse{
     .service-saved{
        .ant-collapse-header{
            padding: 12px 55px!important;
        }
        .success{
            position: absolute;
            left: 16px;
            top: 17px;
            border-radius: 50%;
        }
     }
 }
 .closeMe{
    font-weight: 500;
    color: #fff;
    font-size: 17px;
 }
 .ant-modal-close-x:focus ,.ant-modal-close:focus {
     outline: none ;
 }
 .ant-upload-list-item-name{
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
 }
 .status-btn{
    pointer-events: none;
    cursor: not-allowed;  
 }
 .file-list{
     margin-bottom: 20px;
     .each-file{
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: rgba(0, 0, 0, 0.65);
        font-size: 13px;
        line-height: 2.5715;
        list-style: none;
        border-bottom: 1px solid #f9f5f5;
        a{
            width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba(0, 0, 0, 0.65);
            font-size: 13px;
        }
     }
 }
 .footer-logo{
     height: 47px;
     display: block;
 }
 .project-info-container{
     .form-collapse .ant-collapse-content{
        transition:height 0.8s ease-in-out !important; 
        opacity: 1!important;
     }
     .ant-collapse-content-inactive{
        height: 0px !important;
        display: none ;
     }
 }
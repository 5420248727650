
@media only screen and (min-width: 320px) and (max-width: 767px){
    .r-ml-10{
        margin-left: 10px!important;
    }
    .r-mt-10{
        margin-top: 10px;
    }
    .r-pad-0{
        padding: 0px !important;
    }
    .r-padt-0{
        padding-top: 0px !important;
    }
    .card-update-box-div{
        left: 18% !important;
    }
    .em-main-container-fluid{
        .em-service-table-card {
            &.billing-info-main{
                .billing-info-row-heading {
                    .add-payment-btn{
                        width: auto;
                        height: 40px;
                        font-size: 11px;
                    }
                }
            }
        }
    }
    span.ant-radio + *{
        display: inline-flex;
        white-space: initial;
    } 
    .em-btn-all.em-form-inner-btn{
        box-shadow: none !important;
    }
    .em-btn-all.em-form-inner-btn .ant-btn{
        width:40% !important;
    }
    .em-drawer-main-row{
        .edit-footer{
            button{
                width:50% !important;
            }
        }
    }
    .ant-drawer{
        &.lc-modal{
            .ant-drawer-body{
                width:90%;
            }
        }
    }
    .header-logo{
        img{
         width: 28vh;
         max-width: none;
        }
     }
   .responsive-footer{       
    &.custom-footer{
        padding: 50px 15px 60px;
        .address p{
            display: contents;
        }
        .news-letter{
            .ant-input-search{
                width: 80%;
            }
            label{
                font-size: 16px;
            }
        }
        h2.heading{
            font-size: 21px;
        }
    }
    .services-right{
        h2.heading{
            margin-top: 25px;   
        }
        ul{
            li{
                a{
                    font-size: 15px;
                }
            }
        }
    }
   } 
   .space-between{
    display: flex;
    align-items: center;
    justify-content: space-between;
   }
   .header-responsive{
    padding: 0px 0px 16px 0px !important;
    .search-head{
        height:35px;
        width: 80%;
    }
       &.toggle-menu-container{
        .hamburger_menu_btn{
            display: block;
        }
       .mobile-menu{
           display: block;
           .menu-animation{
               transition: 0.3s ease all;
               padding:20px;
               right:-100%;
               display: inline-block;
               height: 100%;
               width: 100%;
               position: fixed;
               z-index: 99;
                top: 0;
                background: #fff;
           }
           &.active {
            .menu-animation{  
                right:0;                
                .ant-menu{
                    padding: 45px 21px;
                    display: block;
                    .ant-menu-item{
                       display: block;
                       text-align: left; 
                       line-height: 4;
                    }
                }
            }
           }
        }
       }
       .ant-menu{
           text-align: center;
           display: none;
       }
       .ant-menu-item{
        margin: 0px 5px !important;
        font-size: 13px !important;
       }
   }
   .header-main{
    .pc-header {
        margin-top: 20px !important;
        img{
            width:60%;
        }
        .pch-content{
            margin-top: 5px;
            h3{
                text-align: center;
                font-size: 18px;
                margin-bottom: 5px;
            }
            p{
                text-align: center;
            }
        }
        .pch-contact{
            margin-top: 15px!important;
        }
    }   
    .pc-after::after{
        display: none;
    }   
   }
   .project-creation-container{
       .right-content-form{
           padding:0px !important;
           margin-top:30px;
           .ant-radio-group-large{
            .ant-radio-button-wrapper{
                font-size: 13px;
                line-height: 1.2;
                height: 45px;
                display: flex !important;
                align-items: center;
            }
           } 
           .select-timeline{
               span{
                   display: inline-flex;
                   .ant-picker-range{
                       &.invisible{
                        display: none;
                       }
                   }
               }
               label{
                font-size: 13px;
                margin-left: 10px !important;
               }
           }
           .aic-form{
               padding:20px;
           }
           form{
               width: 100%;
           }
           .aic-heading{
            font-size:22px;
           }
           .aic-subHeading{
               font-size: 18px;
           }
           .f-row{
            .ant-row{
                &.checkbox-row{
                    .ant-col{
                        display: inline-flex;
                    }
                }
            } 
               .ant-checkbox-wrapper{
                display: inline-flex !important;
                white-space: initial;
                align-items: center;
               }
                span.ant-radio + *{
                    display: inline-flex;
                    white-space: initial;
                }   
                .ant-form{
                    label.ant-checkbox-wrapper{
                    display: flex; 
                    white-space: initial;
                    align-items: center;
                    }
                }
               h6{
                   font-size: 15px;
                   span{
                       font-size: 15px;
                   }
               }
               .fr-input , .ant-select-selector ,.ant-input-password{
                   height:45px !important;
                   font-size: 14px;
               }
               textarea.fr-input{
                height: 80px !important;
               } 
           }
           .pic-services{
               margin-top: 10px !important;
               display: flex !important;
               flex-wrap: wrap;
               padding-left: 0px;
               justify-content: center;
               .each-service{
                padding:5px;
                width: 130px;
                height: 140px;
                min-width: auto;
                margin-right:0px;
                .es-top{
                    height: 65px;
                }
                .check-icon{
                    height: 20px;
                }
                label{
                    font-size: 12px;
                }
                &:nth-child(odd){
                    margin-right:11px;
                }
               }
           }
       }
   }
   .ant-drawer-content-wrapper{
       .ant-drawer-content{
        .ant-drawer-wrapper-body {
            .ant-drawer-header {
                height:auto !important;
                .ant-drawer-title{
                   font-size: 17px !important; 
                   .edit-header{
                       align-items: flex-start !important;
                       flex-direction: column;
                       span{
                        margin: 0 !important;
                        font-size: 14px !important;
                       }
                   }
                }
            }
        }
       }
       width:100% !important;
   }
   .form-collapse{
    .ant-collapse-content{
        padding:0px !important; 
    }
}
   .project-create-responsive{
       &.project-creation-container{
        padding: 30px 5px 20px 5px;
       }
       .em-form-inner-btn{
           &.em-form-inner-btn{
               padding:0px;
               button{
                   margin-top: 10px !important;
                // margin:0px !important;
                width: 139px ;
                height: 44px ;
                font-size: 13px ;
            }
           }
       }
       .form-collapse{
           .ant-collapse-content{
               padding:0px; 
           }
           .service-saved{
               .ant-collapse-header{
                padding: 12px 45px !important;
               }
           }
           .ant-collapse-header{
            font-size: 16px;
            .ant-collapse-extra{
                display: inherit;
                margin: 0px;
                label{
                    display: inline-block;
                    float: left;
                }
            }
           }
           .ant-collapse-content{
               padding: 10px;
           }
       } 
   }
   .ant-btn{
       &.back-button ,&.continue-button{
        width: 139px ;
        height: 44px ;
        font-size: 13px ;
       }
   }
   .ant-modal-centered{
       .ant-modal{
           width:90% !important;
       }
   }
   .ant-tabs-content-holder{
    width: 100%!important;
    padding-left:0px !important;
}
   .project-contain-main{
   
    .ant-tabs-nav{
        margin-top:20px !important;
        padding:0px!important;
        width: 100%!important;
    }
       &.em-main-container-fluid{
           .em-tabs-details-main{
                .em-new-project-btn{
                    top: 0px;
                    height: 37px;
                    width: 144px;
                    font-size: 12px;
                }    
           }
       }
    }
    .dashboard-main{
        .em-main-row-all{
            .em-project-card-row{
                .em-project-card-inner{
                    padding: 18px 0px;
                    h3{
                        font-size: 31px;
                        line-height: 45px;
                    }
                    p{
                        font-size: 13px;
                    }
                }
            }
            .em-service-table-card{
               table{
                   .img-fluid{
                    max-width: initial;
                    height: 18px;
                   }
               } 
            }
            .em-service-details-row{
                height:90px;
                .em-status-dropdown-row{
                    h6{
                        font-size: 13px;
                        margin-right: 10px;
                    }
                }
                .em-text-div-inner{
                    width: 84%;
                    font-size: 15px;
                    small{
                        display: block;
                        margin: 0;
                    }
                }
            }
            .em-heading-all{
                padding:0px;
                h6{
                    font-size: 18px;
                    span{
                        display: block;
                    }
                }
                .ant-btn{
                    &.em-add-btn{
                        height: 36px;
                        font-size: 10px;
                        padding: 0 11px;
                    }
                }
            }
        }
        .dash-services{
            margin-top: 20px;
        }
    }
    .Service-modal{
        width: 100% !important;
        top: 0px;
        height: 100%;
        margin: 0 !important;
        max-width: initial;
        .ant-modal-content{
            min-height: 100vh;
            height:auto;
            padding-bottom: 75px;
        }
        .ant-modal-footer{
            position: absolute;
            width: 100%;
            bottom: 19px;
        }
        .f-row{
            h6{
                width: 100%;
                font-size: 15px;
            }
        }
        .pic-services{
            padding-left:5px;
            text-align: center;
            .each-service{
                height: 145px;
                width: 141px;
                margin-right: 11px;
                min-width: auto;
                label{
                    font-size: 14px;
                }
                .check-icon{
                    height: 20px;
                }
            }
        }
    }
        .em-main-container-fluid{
            .em-heading-breadcrumb-main{
                h6{
                    display: inline-block;
                    margin-right: 20px;
                }
                h5{
                    display: inline-block;
                }
                .ant-breadcrumb{
                    margin-bottom: 15px;
                }
            }
          
        }
        .account-main{
            .em-heading-breadcrumb-main{
                display: flex;
                .account{
                    order:2;
                }
                .breadcrump{
                    order:1;
                }
            }
            &.em-main-container-fluid{
                .em-service-table-card{
                    h5.heading-info-div{
                        margin-bottom: 15px;
                        margin-top: 20px;
                        font-size: 21px;
                    }
                    .card.em-card-custom{
                        padding: 13px 0px;
                        .em-card-inner-details {
                            padding: 15px 0 20px;
                            h6{
                                margin-top: 15px;
                                display: inline-block;
                                margin-right: 10px;
                                width: 40%;
                            }
                            p{
                                display: inline-block;
                            }
                            h5{
                                font-size: 18px;
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
        .project-details-main{
            .em-accordion-row {
                .ant-collapse{
                    .ant-collapse-item{
                        .img-fluid{
                            height: auto;
                            width: 20px;
                        }
                    }
                }
            }
            .project-info-left{
                order:2;
                display: inline-table;

            }
            .breadcrump{
                order:1;
            }
            &.em-main-container-fluid{
                .em-tabs-details-main.em-tabs-details-main-2  {
                    .ant-tabs{
                        .ant-tabs-nav{
                            margin-top: 0px;
                            width:100%;
                            padding:0px;
                            .ant-tabs-tab{
                                font-size: 13px !important;
                                height: 36px;
                                img{
                                    height:20px;
                                }
                            }
                        }
                        .ant-tabs-content-holder{
                            margin-top: 20px;
                        }
                    }
                }
                .em-service-table-card{
                    .billing-info-row-heading{
                        .add-payment-btn{
                            height: 36px;
                            width: 43% !important;
                            margin-top: 20px;
                            font-size: 11px;
                        }
                        .progress-btn{
                            height: 29px;
                            width: 93px;
                            font-size: 13px;
                            margin-left: 10px;
                        }
                    }
                    .billing-info-row-heading{
                        margin: 0 0 17px;
                       .top-details{
                           width: 100%;
                       }
                    }
                    h5.heading-info-div{
                        font-size: 19px;
                        width: auto;
                    }
                    .card.em-card-custom{
                        padding: 17px 7px;
                        .em-card-inner-details{
                            .details{
                                margin: 5px 0px 10px 0px;
                            }
                            h5{
                                font-size: 22px;
                                margin-bottom: 15px;
                            }
                            h6{
                                width: 36%;
                                font-size: 15px;
                                display: inline-block;
                                margin-right: 15px;
                                float: left;
                            }
                            p{
                                width: 57%;
                                font-size: 15px;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
        .services-edit{
            .em-heading-inn-panel{
                font-size: 17px !important;
                span{
                    display: block !important;
                    margin:0px !important;
                    font-size: 14px!important;
                }
            }
            .em-status-btn{
                font-size: 12px!important;
            }
            .em-status-panel{
                width :40%;
            }
        }
        .service-name{
            width: 97%;
        }
        .dropdown-ellipsis{
            margin-left: 0px !important;
        }
        .all-invoice-select{
            margin-top: 20px;
            .ant-select-selector{
                height: 21px!important;
            }
        }
        .password-security{
            .em-card-inner-details{
                h6{
                    width: 100%!important;
                    font-size: 14px;
                }
            }
        }
        .edit-project-main{
            .radio-check{
                .ant-radio-button-wrapper{
                    font-size: 14px;
                    label{
                        margin:0px !important;
                    }
                }
            }
            .ant-picker{
                &.ant-picker-range{
                    height:0px !important;
                    display: block;
                }
            }
        }
        .f-row{
            h6{
                width: 98% !important;
                font-size: 14px !important;
            }
        }
        .em-tabs-project-main{
            .f-row .fr-input, .f-row .ant-select-selector, .f-row .ant-input-password{
                height:38px!important;
            }
        }
}
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .login-container .vc-input input{
        margin-left: 20px !important;
    }
    .sidebarProgress .sp-menu-item h4 ~ img{
        right: 3px !important;
    }
    .Service-modal{
        .pic-services{
            text-align: left;
            padding-left: 10px;
            .each-service{
                width: 205px !important;
                margin-right: 12px;
            }
        }
    }
    .project-creation-container .right-content-form .pic-services{
        .each-service{
            margin-right: 23px !important;
        }
    }
    
  }
@media screen and (device-aspect-ratio: 40/71) {
    .dashboard-main {
        .em-main-row-all {
            .em-service-details-row {
                height:auto;
                .em-status-dropdown-row {
                    h6{
                        font-size: 12px;
                    }
                }
                .em-text-div-inner{
                    font-size: 14px;
                    small{
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .services-edit .em-status-panel{
        width: 46%;
    }
    body {
        .em-main-container-fluid {
            .em-heading-breadcrumb-main {
                h5{
                    font-size: 17px !important;
                }
            }
        }
    }
    .project-details-main.em-main-container-fluid {
        .em-service-table-card {
            .card.em-card-custom {
                .em-card-inner-details {
                    h5{
                        font-size: 20px;
                    }
                    h6{
                        font-size: 14px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .account-main.em-main-container-fluid {
        .em-service-table-card {
            .card.em-card-custom {
                .em-card-inner-details {
                    .ant-btn{
                        font-size: 13px;
                        height: 40px;
                        width: 217px;
                    }
                    h5{
                        font-size: 16px;
                    }
                    h6{
                        font-size: 15px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
    }
        .ant-drawer.em-main-drawer {
            .ant-drawer-content-wrapper {
                .ant-drawer-content {
                    .ant-drawer-wrapper-body .ant-drawer-header .ant-drawer-title{
                        font-size: 19px!important;
                        button{
                            position: absolute;
                            right: 5px;
                            top: 15px;
                        }
                    }
                }
            }
        }
        .login-container{
            .vc-input input{
                width:29px;
            }
        }
    body .em-main-container-fluid .em-tabs-details-main .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
        .ant-tabs-nav-list .ant-tabs-tab{
            .tabs-icon-div{
                img{
                    height: 20px;
                }
            }
            font-size: 12px !important;
        }
    }   
     .project-create-responsive .em-form-inner-btn.em-form-inner-btn button{
        width: 116px;
        height: 41px;
    }
    .ant-checkbox + span{
        font-size: 10px;
    }
    .Service-modal {
        .pic-services {
            text-align: center;
            padding-left: 5px;
            .each-service{
                height: 151px;
                width: 122px;
                margin-right: 10px;
            }
        }
    }
    .ant-btn.back-button, .ant-btn.continue-button{
        width: 120px;
    }
    .sidebarProgress .sp-menu-item h4{
        font-size: 12px!important;
    }
    .header-main {
        .pc-header {
            .pch-content {
                h3{
                    font-size: 16px;
                }
                p{
                    font-size: 14px;
                }
            }
        }
    }
    .project-create-success {
        h6{
            font-size: 16px;
        }
        .view-project-btn{
            margin-top: 30px !important;
        }
    }
    .project-creation-container {
        .right-content-form {
            .pic-services {
                .each-service{
                    width:114px;
                    &:nth-child(odd){
                        margin-right: 9px;
                    }
                }
            }
            .f-row{
                .ant-checkbox-wrapper{
                    display: flex;
                     align-items: center;
                }
                .fr-input , .ant-select-selector,.ant-input-password{
                    height:38px !important;
                }
                h6{
                    font-size: 14px;
                }
            }
            .aic-heading{
                font-size: 19px;
            }
            .aic-subHeading{
                font-size: 16px;
            }
        }
    }
    .responsive-footer.custom-footer{
        .services-right{
            ul li a{
                font-size: 14px;
            }
        }
        .address{
            p{
                font-size: 13px;
            }
        }
        h2.heading{
            font-size: 18px;
        }
        .news-letter{
            label{
                font-size: 15px;
            }
            h3{
                font-size: 20px;
            }
            .ant-input-search{
                width: 100%;
                .ant-input-group {
                    .ant-input{
                        height: 40px;
                    }
                }
            }
        }
    }  
    
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {

}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .r-ml-10{
        margin-left: 10px !important;
    }
    .responsive-footer{
        &.custom-footer{
            padding: 66px 52px 60px;
            .news-letter{
                h3{
                    font-size: 22px;
                }
                label{
                    font-size: 16px;
                }
                .ant-input-search{
                    width: 80%;
                    .ant-input-group{
                        .ant-input{
                            height:42px;
                        }
                    }                   
                }
                
            }
            h2.heading{
                font-size: 20px;
            }
            .services-right{
                ul{
                    li{
                        a{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        
    }
    
    .r-padl-0{
        padding-left: 0px;
    }
    .r-padr-0{
        padding-right: 0px;
    }
    .header-responsive{
        .ant-menu{
            text-align: center;
        }
        .ant-menu-item{
            margin:0px 10px !important;
            font-size: 12px !important;
        }
        .search-head{
            width: 69%;
            height: 31px;            
        }
    }
    .header-main{
        .pc-header {
            margin-top: 20px !important;
            .pc-after::after{
                top: 16%;
                left: 12px;
                height: 57px;
                width: 95%;
            }
            img{
                width:70%;
            }
            .pch-content{
                h3{
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                p{
                    font-size: 14px;
                }
            }
            .pch-contact{
                margin-top: 15px!important;
            }
        }  
    }
    .project-create-responsive{
        .sidebarProgress{
            .sp-menu-item{
                padding-left: 13px;
                h4{
                    font-size: 11px;
                }
            }
            .spmi-drop{
                h5{
                    font-size: 11px;
                }
            }
        }
    }
    .aic-heading{
        font-size:22px !important;
       }
       .account-info-container{
        .aic-subHeading{
            font-size: 17px;
            span{
                font-size: 18px;
            }
        }
       }
       .project-contain-main{
            .ant-tabs-content-holder{
                width: calc(100% - 237px);
            }
            .ant-btn{
                &.em-new-project-btn{
                    top: 0px;
                }
            } 
       }
       .ant-drawer-content-wrapper{
           width:55%!important;
       }
       .project-details-main{
        
        &.em-main-container-fluid{
            .em-heading-breadcrumb-main{
                .project-info-left{
                    .ant-btn.all-project-view-btn{
                        top:auto;
                    }
                }
            }
            .em-tabs-details-main.em-tabs-details-main-2  {
                .ant-tabs{
                    .ant-tabs-nav{
                        width:190px;
                        padding-right: 3%;
                        margin-top: 0px;
                        .ant-tabs-tab{
                            font-size: 12px !important;
                            height: 36px;
                            img{
                                height:20px;
                            }
                        }
                    }
                    .ant-tabs-content-holder{
                        margin-top: 20px;
                        width: calc(100% - 190px);
                    }
                }
            }
            .em-service-table-card{
                &.documents-main{
                    .billing-info-row-heading{
                        .add-payment-btn{
                            width: 50% !important;                        
                        }
                    }
                }
                .billing-info-row-heading{
                        .add-payment-btn{
                            font-size: 13px;
                            width: 28% !important;                        
                        }
                    .progress-btn{
                        height: 29px;
                        width: 93px;
                        font-size: 13px;
                        margin-left: 10px;
                    }
                }
                .billing-info-row-heading{
                    margin: 0 0 17px;
                //    .top-details{
                //        width: 100%;
                //    }
                }
                h5.heading-info-div{
                    font-size: 20px;
                }
                .card.em-card-custom{
                    padding: 17px 7px;
                    .em-card-inner-details{
                        .details{
                            margin: 5px 0px 10px 0px;
                        }
                        h5{
                            font-size: 22px;
                            margin-bottom: 15px;
                        }
                        h6{
                            font-size: 14px !important;
                            margin-right: 15px;
                        }
                        p{
                            font-size: 13px;
                            a{
                                margin-left:3px;
                            }
                        }
                    }
                }
            }
        }
    }       
    .account-main{
        
        &.em-main-container-fluid{
            .em-service-table-card{
                h5.heading-info-div{
                    margin-bottom: 15px;
                    margin-top: 20px;
                    font-size: 21px;
                }
                .card.em-card-custom{
                    padding: 13px 0px;
                    .em-card-inner-details {
                        padding: 15px 0 20px;
                        h6{
                            margin-top: 15px;
                            margin-right: 10px;
                            font-size: 15px;
                        }
                        p{
                            font-size: 14px;
                        }
                        h5{
                            font-size: 19px;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
    .project-creation-container{
        .right-content-form{
            form{
                width:100%;
            }
            .pic-services{
                padding-left: 10px;
                .each-service{
                    margin-right: 13px;
                    width: 30%;
                    padding:2px;
                    .es-top{
                        height:75px;
                    }
                }
            }
        } 
    }
   
    .ant-drawer{
        .lc-modal{
            .ant-drawer-body{
                padding:15px;
            }
        }
    }
    .edit-footer{
        padding:0px !important;
    }
    .ant-modal-centered{
        .ant-modal{
            width: 53% !important;
        }
    }
   .user-account-container{
       .ant-tabs-nav{
           width: 210px;
           padding: 3%;
           padding-left: 0;
           margin: 0;
       }
   }
   body .em-main-container-fluid .em-tabs-details-main.em-tabs-details-main-2.user-account-container .ant-tabs{
       .ant-tabs-nav{
        width: 210px;
        padding: 3%;
        padding-left: 0;
        margin: 0;   
        .ant-tabs-nav-wrap{
            .ant-tabs-nav-list{
                .ant-tabs-tab{
                    .ant-tabs-tab-btn{
                        font-size: 13px;
                    }
                }
            }
        } 
       }
       .ant-tabs-content-holder{
        width: calc(100% - 210px);
       }
   }
   .Service-modal{
       .ant-modal-body{
           text-align: center;
       }
       .pic-services{
           padding-left: 10px;
           .each-service{
                width:203px;
           }
       }
   }
   .right-content-form{
       .ant-collapse-content{
        .f-row{
            h6{
                width: 98%;
            }
        }
       }
   }
.ant-drawer-content-wrapper{
    .ant-drawer-content{
     .ant-drawer-wrapper-body {
         .ant-drawer-header {
             height:auto !important;
             .ant-drawer-title{
                font-size: 17px !important; 
                .edit-header{
                    align-items: flex-start !important;
                    flex-direction: column;
                    span{
                     margin: 0 !important;
                     font-size: 14px !important;
                    }
                }
             }
         }
     }
    }
}
    .edit-project-main{
        .radio-check{
            .ant-radio-button-wrapper{
                font-size: 14px;
                label{
                    margin:0px !important;
                }
            }
        }
        .ant-picker{
            &.ant-picker-range{
                height:0px !important;
                display: block;
            }
        }
    }
    .f-row h6{
        width:85% !important;
    }
    span.ant-radio + *{
        display: inline-flex;
        white-space: initial;
    }
    .dashboard-main{
        .em-main-row-all {
            .em-service-details-row {
                    .dc-complete{
                        padding-left: 0px;
                        h6{
                            font-size: 12px;
                            margin-right:5px;
                        }
                    }
                
            }
        }
       
    }
}
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    
}
body {
  font-family: 'Lato', sans-serif !important;
  background: #F9F9F9;

  a, a:hover {
    text-decoration: none !important;
  }

  .em-main-container-fluid {
    padding: 30px 15px;

    .container {
      padding: 0 5px;
    }

    .em-pagination-row {
      padding-top: 5px;
    }

    .em-service-table-card {
      background: #ffffff;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
      height: auto;

      .em-service-table {
        table {
          .ant-table-thead {
            tr {
              th {
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: -0.24px;
                text-transform: uppercase;
                color: #333333;
                padding: 16px 30px;
                text-align: center;
                background: #ffffff;
                border-bottom: 1px solid #E0E0E0;

                &:nth-child(1) {
                  text-align: left;
                }

                &:nth-child(3) {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }

          .ant-table-tbody {
            tr {
              td {
                border-bottom: 1px solid #F2F2F2;
                font-weight: 300;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.24px;
                color: #333333;
                text-align: center;
                padding: 14px 0;
                cursor: pointer;

                &:nth-child(1) {
                  text-align: left;
                  font-weight: 400;
                  padding: 14px 30px;
                }

                .icon-img {
                  margin-right: 10px;
                }

                .ant-btn {
                  background: #F2F2F2;
                  border-radius: 4px;
                  height: 28px;
                  width: 100px;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: -0.24px;
                  color: #333333;
                  font-weight: 400;

                  &.danger-btn-error {
                    background: #FFE8E8;
                    border: 1px solid #EB9090;
                  }

                  &.progress-btn-yellow {
                    background: #FFF8D6;
                    border: 1px solid #F2C94C;
                  }

                  &.success-btn-green {
                    background: rgba(111, 207, 151, 0.3);
                    border: 1px solid #6FCF97;
                  }

                  &.success-btn-complete {
                    background: #F2F2F2;
                    border: 1px solid #E0E0E0;
                  }
                }
              }
            }
          }
        }

        .ant-pagination {
          padding: 0 30px 30px;
        }

        &.em-documents-table {
          table {
            .ant-table-thead {
              tr {
                th {
                  text-align: left;

                  &:nth-child(1) {
                    width: 450px;
                  }

                  &:nth-child(2) {
                    justify-content: flex-start;
                  }
                }
              }
            }

            .ant-table-tbody {
              tr {
                td {
                  text-align: left;
                  padding: 14px 30px;

                  &:nth-child(1) {
                    width: 450px;
                  }

                  .ant-btn {
                    width: 35px;
                  }
                }
              }
            }
          }
        }

        &.em-project-activity-table {
          table {
            .ant-table-thead {
              tr {
                th {
                  text-align: left;

                  &:nth-child(1) {

                  }

                  &:nth-child(2) {
                    justify-content: flex-start;
                  }
                }
              }
            }

            .ant-table-tbody {
              tr {
                td {
                  text-align: left;
                  padding: 14px 30px;

                  a {
                    margin-left: 10px;
                  }

                  &:nth-child(1) {
                    font-weight: 300;
                  }

                  &:nth-child(2) {
                    font-weight: 400;
                  }
                }
              }
            }
          }
        }
      }

      h5.heading-info-div {
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
        margin-bottom: 30px;
      }

      .billing-info-row-heading {
        margin: 0 0 30px;

        .add-payment-btn {
          background: #2A3446;
          border-radius: 8px;
          width: 25%;
          height: 48px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.05em;
          color: #ffffff;
        }

        .progress-btn {
          background: rgba(111, 207, 151, 0.3);
          height: 33px;
          width: 125px;
          margin-left: 20px;
          border: 1px solid #6FCF97;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #333333;
        }

        .all-invoice-select {
          .ant-select {
            .ant-select-selector {
              border: 0;
              background: transparent;

              .ant-select-selection-placeholder {
                font-size: 14px;
                line-height: 17px;
                text-transform: uppercase;
                color: #4F4F4F;
                opacity: 1;
              }
            }

            .ant-select-arrow {
              top: 25%;

              .anticon {
                font-size: 14px;
                line-height: 17px;
                color: #4F4F4F;
              }
            }
          }
        }
      }

      .card {
        &.em-card-custom {
          background: #ffffff;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
          padding: 30px 15px;
          border: none;

          .em-card-inner-details {
            border-bottom: 1px solid #E0E0E0;
            padding: 15px 0 30px;

            h5 {
              font-weight: 400;
              font-size: 22px;
              color: #000000;
              margin-bottom: 25px;
            }

            h6 {
              font-weight: 400;
              font-size: 16px;
              color: #828282;
              margin-bottom: 5px;
            }

            p {
              font-weight: 400;
              font-size: 16px;
              color: #333333;
            }

            .ant-btn {
              margin-top: 15px;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: 0.05em;
              text-transform: uppercase;
              color: #4F4F4F;
              border: 1px solid #828282;
              box-sizing: border-box;
              border-radius: 8px;
              height: 44px;
              width: 250px;

              img {
                margin-right: 8px;
              }

              &.em-view-invoice {
                background: #F2F2F2;
                border: 1px solid #E0E0E0;
                margin-left: 15px;
              }

              &.view-edit-brief {
                width: 200px;
              }
            }

            &:last-child {
              border: none;
            }
          }

          .em-billing-info-inn {
            .em-card-inner-details {
              min-height: 130px;
              padding-bottom: 0;

              p {
                .color-card-img-div {
                  padding-right: 10px;

                  .card-rounded-tag {
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    display: inline-block;
                    background: #EB001B;
                    position: relative;

                    &.color-red {
                      z-index: 9;
                    }

                    &.color-orange {
                      background: #F79E1B;
                      z-index: 0;
                      left: -5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .ant-pagination {
      .ant-pagination-prev, .ant-pagination-next {
        .ant-pagination-item-link {
          color: #818E9B;
          font-size: 14px;
          line-height: 10px;
        }
      }

      .ant-pagination-item {
        min-width: 30px;
        height: 30px;

        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 30px;
          text-align: center;
          letter-spacing: 1.25px;
          text-transform: uppercase;

          &:not([href]) {
            color: #818E9B;
          }
        }

        &.ant-pagination-item-active {
          border-color: transparent;
          background: transparent;

          a {
            &:not([href]) {
              color: #F2994A;
            }
          }
        }
      }
    }

    .em-tabs-details-main {
      .ant-tabs {
        display: inline-block;

        .ant-tabs-nav {
          width: 237px;
          float: left;
          margin: 85px 0 0;
          padding-right: 45px;

          &:before {
            display: none;
          }

          .ant-tabs-nav-wrap {
            display: inline-block;
            white-space: normal;

            .ant-tabs-nav-list {
              display: inline-block;
              width: 100%;

              .ant-tabs-tab {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 52px;
                border: 0;
                border-right: 2px solid transparent;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.24px;
                color: #333333;
                background: transparent;

                .num-div {
                  color: #828282;
                }

                .ant-tabs-tab-btn {
                  width: 100%;
                }

                &.ant-tabs-tab-active, &:hover {
                  background: #FFFFFF;
                  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
                  border-color: #26B1FF;

                  .ant-tabs-tab-btn {
                    color: #333333;
                  }
                }
              }
            }
          }
        }

        .ant-tabs-content-holder {
          width: calc(100% - 237px);
          float: left;
        }
      }

      &.em-tabs-details-main-2 {
        .ant-tabs {
          .ant-tabs-nav {
            width: 316px;
            margin: 20px 0 0 0;
            padding-right: 60px;

            .ant-tabs-nav-wrap {
              .ant-tabs-nav-list {
                .ant-tabs-tab {
                  height: 42px;
                  text-transform: uppercase;

                  &.ant-tabs-tab-active, &:hover, &:focus {
                    .ant-tabs-tab-btn {
                      color: #2F8BE0;
                    }
                  }

                  .ant-tabs-tab-btn {
                    .tabs-icon-div {
                      width: 40px;
                    }

                    .text-div {
                      width: calc(100% - 40px);
                      float: left;

                      span {
                        color: #BDBDBD;
                      }
                    }
                  }
                }
              }
            }
          }

          .ant-tabs-content-holder {
            width: calc(100% - 316px);
            padding-left: 15px;
          }
        }

        &.em-tabs-project-main {
          .ant-tabs {
            .ant-tabs-nav {
              padding-top: 6%;
            }
          }
        }
      }

      .em-new-project-btn {
        background: #2A3446;
        border-radius: 8px;
        height: 48px;
        width: 190px;
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
        top: 50px;
      }
    }

    &.em-container-fluid-extra {
      .container {
        max-width: 1245px;
      }
    }

    .em-heading-breadcrumb-main {
      h6 {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #828282;
      }

      h5 {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #333333;

        a {
          color: #2F8BE0;
          font-size: 12px;
          line-height: 14px;
        }
      }

      .ant-breadcrumb {
        span {
          color: #828282;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;

          a {
            color: #BDBDBD;
          }
        }
      }

      .project-info-left {
        .ant-btn.all-project-view-btn {
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          position: relative;
          top: 100%;
        }
      }
    }
  }
}

.em-btn-all {
  padding-top: 60px;

  .ant-btn {
    border-radius: 8px;
    height: 50px;
    padding: 0 15px;
    width: 14%;
    box-shadow: none;
    background: #E0E0E0;
    color: #4F4F4F;
    border: 2px solid #E0E0E0;
    text-transform: uppercase;

    &.ant-btn-primary {
      border-color: #2F8BE0;
      background: #2F8BE0;
      color: #ffffff;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.05em;
      &:hover{
        background: #26B1FF;
        border-color: #26B1FF;
      }
    }

    &.send-payment {
      background: #FFFFFF;
      border-color: #2F8BE0;
      color: #2F8BE0;
      margin: 0 30px;
    }
  }

  &.em-form-inner-btn {
    padding: 20px 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    bottom: 0;
    background: #ffffff;
    z-index: 9999;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);

    .ant-btn {
      margin-left: 15px;
      width: 30%;
    }
  }

  &.em-billing-btn-all {
    padding-right: 15px;
  }
}

.ant-drawer.em-main-drawer {
  position: fixed !important;

  .ant-drawer-content-wrapper {
    overflow: auto;

    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          background: #000000;
          height: 63px;
          padding: 15px 30px;

          .ant-drawer-title {
            font-weight: bold;
            font-size: 18px;
            line-height: 29px;
            letter-spacing: -0.24px;
            color: #ffffff;

            span {
              color: #BDBDBD;
              margin-left: 15px;
              font-size: 19px;
            }

            .close-btn-drawer {
              span {
                color: #fff;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
              }
            }
          }
        }

        .ant-drawer-body {
          padding: 30px;
          .em-drawer-main-row {
            padding-bottom: 65px;
            overflow: auto;

            .ant-form-item.group-floating-label {
              .ant-form-item-label {
                width: 100%;
                text-align: left;

                label {
                  font-weight: 400;
                  font-size: 16px;
                  color: #333333;

                  span {
                    margin-left: 5px;
                    color: #BDBDBD;
                  }
                }
              }

              .ant-form-item-control {
                .ant-form-item-control-input {
                  .ant-input, .ant-picker, .ant-select-selector {
                    background: #F8F8F8;
                    width: 100%;
                    border: 1px solid #E0E0E0;
                    box-sizing: border-box;
                    border-radius: 6px;
                    height: 48px;
                    font-size: 15px;
                    letter-spacing: 0.01em;

                    &::-webkit-input-placeholder {
                      color: #BDBDBD;
                    }

                    &.textAreaInput {
                      height: 200px;
                    }
                  }
                }

                .ant-select-selection-placeholder {
                  display: flex;
                  align-items: center;

                  &::-webkit-input-placeholder {
                    color: #BDBDBD;
                  }
                }

                .ant-upload.ant-upload-drag {
                  background: #F8F8F8;
                  border: 1px solid #E0E0E0;
                  border-radius: 6px;

                  .ant-upload.ant-upload-btn {
                    padding: 58px 0;

                    .ant-upload-drag-container {
                      font-weight: 400;
                      font-size: 18px;
                      line-height: 22px;
                      color: #000000;

                      span {
                        color: #2F8BE0;
                        margin-left: 5px;
                      }
                    }
                  }
                }

                .ant-upload-list {
                  .ant-upload-list-item {
                    border-bottom: 1px solid #F2F2F2;
                    font-size: 14px;
                    line-height: 17px;
                    padding: 5px 0;
                    color: #828282;
                    height: 34px;

                    .ant-upload-text-icon {
                      .anticon-paper-clip {
                        font-size: 16px;
                        line-height: 17px;
                        color: #828282;
                      }
                    }

                    .ant-upload-list-item-name {
                      color: #828282;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.em-billing-drawer {
    .ant-drawer-content-wrapper {
      .ant-drawer-content {
        .ant-drawer-wrapper-body {
          .ant-drawer-body {
            padding: 0;

            .em-drawer-main-row {
              .ant-radio-group {
                width: 100%;

                .ant-radio-wrapper {
                  width: 100%;
                  border-bottom: 1px solid #E0E0E0;
                  padding: 30px;
                  display: flex;
                  align-items: center;

                  .ant-radio {
                    width: 60px;

                    .ant-radio-inner {
                      width: 27px;
                      height: 27px;
                      border-color: #828282;
                      border-style: solid;
                      border-width: 2px;

                      &:after {
                        top: 4px;
                        left: 4px;
                        width: 15px;
                        height: 15px;
                        background-color: #26B1FF;
                      }
                    }

                    &.ant-radio-checked {
                      .ant-radio-inner {
                        border-color: #26B1FF;
                      }

                      &:after {
                        width: 27px;
                        height: 27px;
                        border-color: #26B1FF;
                      }
                    }
                  }

                  span.ant-radio + * {
                    width: calc(100% - 60px);
                    display: inline-block;

                    h6 {
                      font-weight: bold;
                      font-size: 18px;
                      color: #000000;
                      margin-bottom: 5px;
                    }

                    p {
                      margin-bottom: 0;
                      font-size: 15px;
                      letter-spacing: 0.01em;
                      color: #828282;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .add-address-new {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.05em;
      color: #4F4F4F;
      border: 1px solid #828282;
      box-sizing: border-box;
      border-radius: 8px;
      background: transparent;
      height: 48px;
      padding: 0 20px;
      margin: 30px 30px 0 0;
    }
  }

  &.em-address-drawer {
    .ant-drawer-content-wrapper {
      .ant-drawer-content {
        position: relative;

        .ant-drawer-wrapper-body {
          .ant-drawer-header {
            background: #000000;

            .ant-drawer-title {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

.switch-btn-div {
  right: 30px;
  top: 30px;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
  }

  .ant-switch {
    &.ant-switch-checked {
      background: #35C759;
      border-radius: 22.0521px;
    }
  }
}

.card-update-box-div {
  bottom: 15px;
  left: 90px;
  background: rgba(42, 52, 70, 0.9);
  opacity: 0.9;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1), 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 220px;
  min-height: 107px;
  padding: 20px 25px 20px 20px;
  transition: 0.3s ease all;

  p {
    font-size: 14px;
    line-height: 15px;
    font-weight: 400;
    color: #ffffff;
  }

  .ant-btn {
    &.close-btn {
      top: 0;
      right: 5px;
    }

    &.undo-btn {
      color: #F2994A;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      bottom: 5px;
      right: 15px;
    }
  }
}
.password-security{
  .ant-drawer-body{
    padding-bottom:0px !important;
  }
  .em-drawer-main-row{
    padding : 0px !important;
    height:100%;
  }
}
.input-Password{
  .ant-input-password{
    background: #F8F8F8;
    width: 100%;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 6px;
    height: auto;
    font-size: 15px;
    letter-spacing: 0.01em;
  }
  input{
    background: transparent !important;
    box-sizing: border-box;
    border-radius: 6px;
    height: 40px !important;
    font-size: 15px;
    letter-spacing: 0.01em;
    padding: 0;
    border: none !important;
    outline: none;
  }
}
.ant-tooltip-placement-top{
  &.ant-slider-tooltip{
    z-index:100 !important; 
  }
}
.edit-project-main{
  z-index: 99;
}

.em-main-row-all {
  .em-service-table-card {
    &.em-table-invoices-card {
      height: auto;

      .em-service-table {
        table {
          .ant-table-thead {
            tr {
              th {
                &:nth-child(2) {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                &:nth-child(3) {
                  display: table-cell;
                }
              }
            }
          }
        }
      }
    }
  }
}

body {
  .em-main-container-fluid {
    &.em-container-fluid-invoice-white {
      background: #ffffff;

      .container {
        max-width: 1390px;
        padding-right: 30px;

        .em-invoice-details-1 {
          padding: 0 0 15px;

          .em-invoice-text-details {
            h6 {
              color: #828282;
              font-weight: 400;
              font-size: 16px;
              line-height: 18px;
            }

            p {
              color: #333333;
              font-weight: 400;
              font-size: 16px;
              line-height: 18px;
            }

            .ant-form {
              .ant-select {
                width: 100%;

                .ant-select-selector {
                  background: #F8F8F8;
                  border: 1px solid #E0E0E0;
                  box-sizing: border-box;
                  border-radius: 6px;
                  height: 45px;

                  .ant-select-selection-search {
                    .ant-select-selection-search-input {
                      height: 44px;
                    }
                  }

                  .ant-select-selection-placeholder {
                    line-height: 45px;
                    font-size: 16px;
                    letter-spacing: 0.01em;
                    font-weight: 400;
                    color: #828282;
                  }
                }
                .ant-select-selection-item{
                  display: flex;
                  align-items: center;
                }
              }

              .ant-picker {
                background: #F8F8F8;
                border: 1px solid #E0E0E0;
                box-sizing: border-box;
                border-radius: 6px;
                height: 45px;
                width: 100%;

                input {
                  font-size: 16px;
                  letter-spacing: 0.01em;
                  font-weight: 400;
                  color: #828282;
                }
              }
            }
          }

          .em-open-balance-row {
            h5 {
              font-weight: 400;
              font-size: 20px;
              line-height: 24px;
              color: #636363;
            }

            h1 {
              font-weight: 600;
              font-size: 50px;
              line-height: 60px;
              color: #000000;
            }
          }
        }
      }
    }

    &.em-container-fluid-invoice {
      padding: 45px 30px 30px;

      .container {
        max-width: 1415px;
      }
    }
  }
}

.ant-table-wrapper.em-invoice-table {
  table {
    .ant-table-thead {
      tr {
        th {
          background: #F9F9F9;
          text-align: right;
          padding: 17px 15px;
          color: #636363;

          &:nth-child(1), &:nth-child(2) {
            text-align: left;
          }
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          background: #ffffff;
          text-align: right;
          padding: 15px;
          color: #000000;

          &:nth-child(1), &:nth-child(2) {
            text-align: left;
          }

          .ant-input {
            border: 1px solid #999999;
            box-sizing: border-box;
            border-radius: 4px;
            background: #ffffff;
            height: 36px;
            color: #000000;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            width: 74%;
            text-align: right;

            &::-webkit-input-placeholder {
              color: #BDBDBD;
            }
          }
        }
      }
    }
  }

  .ant-pagination {
    display: none;
  }
}

.em-invoice-table-total.ant-table-wrapper {
  padding-bottom: 60px;

  table {
    .ant-table-thead {
      tr {
        th {
          display: none;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          text-align: right;
          padding: 20px 15px;
          color: #000000;
          font-size: 16px;
          line-height: 18px;
          background: #f9f9f9;
          border: none;

          &:nth-child(1) {
            text-align: left;
            min-width: 590px;
          }
        }
      }
    }
  }

  .ant-pagination {
    display: none;
  }

  &.pay-invoice-table {
    .ant-table-tbody {
      tr {
        td {
          &:nth-child(1) {
            min-width: 210px;
          }
        }
      }
    }
  }
}
.ant-select-item-option{
      min-height: 40px;
      padding: 10px 15px;
}
.drawer-btn-plus{
   padding: 8px 15px;
    display: flex;
  align-items: center;
}
.em-left-column{ 
  thead{
    tr th{
      text-align:left !important;
    }
  }
  tbody{
    tr{
      td{
        padding: 14px 30px !important;
        text-align: left !important;
      }
    }
  }
}

  .ant-popover-arrow{
    display: none;
  }
  .ant-popover-inner-content{
    background: #2A3446;
    border-radius: 10px;
    p{
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 13px;
      display: flex;
      align-items: center;
      letter-spacing: 0.07px;
      color: #FFFFFF;
      margin: 10px 0px;
      cursor: pointer;
      span{
        opacity: 0.7;
      }
      img{
        margin-right: 10px;
      }
    }
  }

  .text-ellipses{
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
  }